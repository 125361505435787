<!--
    ButtonBase is responsible for drawing button-shaped things.
    It's currently extended by the Button and ButtonLink components.
    In the future it should probably be extended by Select, too.
    You're generally not going to instantiate a ButtonBase component directly.

    Buttons typically have a white background and orange text and outline.
    You can set the text/outline color with some preset props on the button (see the style guide for options)
    You can also override this color manually as `--button-color`,
    E.g. <Button style="--button-color: red;">

    To swap the button color and the background color, use the `primary` prop.

    Some of our existing color-changing props don't respect this behavior.
    Avoid using these to keep the Button API predictable and allow for easier palette changes in the future.

    A Button can optionally have a "before" and "after" slot.
    The contents of these slots will be separated by a thin rule before or after the main content.
-->

<style>
:root {
    --button-color: var(--highlight, red);
    --button-background: var(--background, white);
}

.button {
    align-items: stretch;
    background: var(--button-background);
    border: 1px solid;
    border-radius: 5px;
    box-shadow: 0 0 0 -15em inset;
    box-sizing: border-box;
    color: var(--button-color);
    cursor: pointer;
    display: inline-flex;
    font-family: var(--sans-font);
    font-size: 15px;
    font-weight: bold;
    line-height: 1.3;
    margin: 0;
    padding: 0;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.1s, border-color 0.1s, box-shadow 0.1s, color 0.1s, opacity 0.2s;
}
.button--lg {
    font-size: 18px;
}
.button--xl {
    font-size: 24px;
}
.button:focus {
    outline: none;
}

.button:hover {
    box-shadow: none !important;
    text-decoration: none;
}

.button:active {
    box-shadow: none !important;
}

.button:disabled,
.button[data-disabled] {
    opacity: 0.5;
    pointer-events: none;
}

.button--bold {
    font-weight: 600;
}

.button--block {
    display: flex;
    width: 100%;
}

.button--pill {
    border-radius: 2em;
    font-size: 0.8em;
    min-width: 1.4em;
}

.button--dashed {
    border-style: dashed;
}

.button--primary {
    background: var(--button-color);
    border-color: transparent;
    color: var(--button-background);
}

.button--primary-gradient {
    background: var(--button-color);
    background: linear-gradient(to right, var(--button-color), var(--secondary));
    color: var(--button-background);
}

.button--secondary-gradient {
    background: var(--green);
    background: linear-gradient(to right, var(--green), var(--green-lighter));
    color: var(--button-background);
}

.button--primary-gradient:active {
    box-shadow: none !important;
}

.button--black {
    background: #000000;
    border-color: transparent;
    color: #ffffff !important;
}

.button--black:hover,
.button--black:visited,
.button--black:active,
.button--black:focus {
    background: #000000;
    border-color: transparent;
    color: #ffffff !important;
}

.button--gray {
    background: #bcbcbc;
    border-color: transparent;
    color: var(--button-background);
}

.button--gray-outline {
    border-color: #bcbcbc;
    color: #bcbcbc;
    background: transparent;
}

.button--darkgray {
    background: #555;
    border-color: transparent;
    color: var(--button-background);
}

.button--darkgray-outline {
    border-color: #555;
    color: #555;
    background: transparent;
}

.button--darkgray-outline:hover,
.button--darkgray-outline:focus,
.button--darkgray-outline:visited,
.button--darkgray-outline:active {
    box-shadow: none;
}

.button--orange:hover {
    border-color: #a12806;
}

.post-action-button .button__content {
    padding: 0px;
    margin-right: 15px;
}

.button-tags.button span.button__content {
    padding: 0.2em 1em !important;
    font-size: 14px !important;
    margin-right: 0 !important;
}

.post-action-button .button__content > span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button--noborder:hover {
    border-color: transparent;
    box-shadow: none;
}

.button--gray:hover {
    border-color: #808080;
}

.button--primary:not(:disabled):hover {
    box-shadow: 0 0 20em -10em black inset;
}

.button--primary:not(:disabled):active {
    box-shadow: 0 0 20em -5em black inset;
}

.button--thick-border {
    box-shadow: 0 0 0 1px inset; /* Not sure how well this'll work with complex buttons. */
}

.button--muted {
    --button-color: var(--muted);
}
.button--success {
    --button-color: var(--success);
}
.button--facebook {
    --button-color: var(--facebook);
}
.button--twitter {
    --button-color: var(--twitter);
}
.button--google-plus {
    --button-color: var(--google-plus);
}

.button--light-gray {
    border-color: var(--light-gray);
    --button-color: var(--muted);
}

.button--green {
    --button-color: var(--success);
}
.button--green-bg {
    --button-color: #ffffff;
    --button-background: var(--success);
}

.button--green-bg:hover {
    --button-color: #ffffff;
    --button-background: var(--success);
    border: 1px solid var(--success);
}

.button--yellow {
    --button-color: #f6e615;
}

.button--red {
    --button-color: #ff0002;
}

.button--tag {
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 50px;
}

.button--tag .button__content {
    padding: 0.6em;
}

.new-member-tag-right .button__content {
    justify-content: flex-end !important;
}

.new-member-tag-left .button__content {
    justify-content: flex-start !important;
}

.button-post .button__content {
    padding: 0.6em 0.5em;
}

.button--tag svg {
    padding-right: 4px;
    max-height: 27px;
}

.button--active-tag {
    background: var(--button-color);
    background: linear-gradient(to right, var(--button-color), var(--secondary));
    color: white;
    border: 1px solid var(--primary-light);
    font-weight: bold;
}

.button--purple {
    --button-color: #b846fd;
}

.button--purpleTag {
    --button-color: var(--crowdpac-tv);
}

.button--blue {
    --button-color: #17b2dd;
}

.button--blueTag {
    --button-color: var(--blogs);
}

.button--neutral2Tag {
    --button-color: var(--hidden);
}

.button--accent1 {
    --button-color: var(--accent1);
}

.button--accent2 {
    --button-color: var(--accent2);
}

.button--neutral2 {
    --button-color: var(--neutral2);
}

.button--tall {
    padding: 0.4em 1em;
}

.button__content {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding: 0.6em 1em;
}

.button--icon-only .button__content {
    padding: 0.6em;
}

@media screen and (max-width: 767px) {
    .button--thin .button__content,
    .button--primary .button__content {
        padding: 0.6em;
    }
}

.button--tall .button__content {
    font-size: 18px;
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .button--tall .button__content {
        font-size: 16px;
    }
}

.button__content:not(:first-child) {
    box-shadow: 1px 0px 1px -1px inset;
}

.button__content--cap {
    flex-grow: 0;
}

.button--pill > .button__content {
    padding: 0 0.2em;
}

.button--pill--circular > .button__content {
    font-size: 11px;
    padding: 0 0.4em;
}

.button--thin > .button__content {
    padding: 0.2em 1em;
    font-size: 14px;
}

.button--noborder {
    border-color: transparent;
}

.btn-follow.button span.button__content,
.btn-message.button span.button__content {
    padding: 0;
}
</style>

<script>
const TEMPLATE_CONTENT = `
        <span v-if="$slots.before" class="button__content button__content--cap">
            <span>
                <slot name="before" />
            </span>
        </span>

        <span class="button__content">
            <span v-if="!loading">
                <slot />
            </span>
            <span v-if="loading">
                <img src="https://assets.staging.crowdpac.com/v2/demo/all/img/spin.gif" />
            </span>
        </span>

        <span v-if="$slots.after" class="button__content button__content--cap">
            <span>
                <slot name="after" />
            </span>
        </span>
    `;

export default {
    props: {
        block: Boolean,
        bold: Boolean,
        thin: Boolean,
        dashed: Boolean,
        primary: Boolean,
        primaryGradient: Boolean,
        secondaryGradient: Boolean,
        pill: Boolean,
        gray: Boolean,
        green: Boolean,
        purple: Boolean,
        purpleTag: Boolean,
        blue: Boolean,
        blueTag: Boolean,
        accent1: Boolean,
        accent2: Boolean,
        neutral2: Boolean,
        muted: Boolean,
        noborder: Boolean,
        thickBorder: Boolean,
        circular: Boolean,
        lightGray: Boolean,
        lightGrayTag: Boolean,
        yellow: Boolean,
        red: Boolean,
        success: Boolean,
        facebook: Boolean,
        twitter: Boolean,
        googlePlus: Boolean,
        iconOnly: Boolean,
        loading: Boolean,
        orange: Boolean,
        grayOutline: Boolean,
        tall: Boolean,
        tag: Boolean,
        activeTag: Boolean,
        black: Boolean,
        lg: Boolean,
    },

    computed: {
        className() {
            return {
                button: true,
                'button--bold': this.bold,
                'button--block': this.block,
                'button--thin': this.thin,
                'button--dashed': this.dashed,
                'button--primary': this.primary,
                'button--primary-gradient': this.primaryGradient,
                'button--secondary-gradient': this.secondaryGradient,
                'button--gray': this.gray,
                'button--green': this.green,
                'button--yellow': this.yellow,
                'button--red': this.red,
                'button--pill': this.pill,
                'button--lg': this.lg,
                'button--blue': this.blue,
                'button--blueTag': this.blueTag,
                'button--accent1': this.accent1,
                'button--accent2': this.accent2,
                'button--neutral2': this.neutral2,
                'button--purple': this.purple,
                'button--purpleTag': this.purpleTag,
                'button--muted': this.muted,
                'button--noborder': this.noborder,
                'button--thick-border': this.thickBorder,
                'button--tag': this.tag,
                'button--active-tag': this.activeTag,
                'button--pill--circular': this.circular,
                'button--light-gray': this.lightGray,
                'button--light-gray-tag': this.lightGrayTag,
                'button--success': this.success,
                'button--facebook': this.facebook,
                'button--twitter': this.twitter,
                'button--google-plus': this.googlePlus,
                'button--icon-only': this.iconOnly,
                'button--orange': this.orange,
                'button--gray-outline': this.grayOutline,
                'button--tall': this.tall,
                'button--black': this.black,
            };
        },
    },

    templateContent: TEMPLATE_CONTENT,

    template: `
            <span :class="className">${TEMPLATE_CONTENT}</span>
        `,
};
</script>
